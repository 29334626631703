<template>
  <div class="crosschain-style">
    <div class="outbox center-center">
      <el-card class="box-card" v-loading="isloading">
        <div class="box1 between-center">
          <div class="b1-t1"></div>
          <div class="b1-t2">{{$t('text.t131')}}</div>
          <img src="@/assets/img/img32.png" alt="" class="b1-t1" @click="goAhead('/crosschainlist')" />
        </div>
        <van-divider>{{$t('text.t131')}}</van-divider>
        <div class="box2 between-center">
          <div class="start-center">
            <img :src="chainId=='0x2d20'?$concatImg('img44'):$concatImg('img43')" alt="" class="b2-i1" />
            <div class="b2-t1">{{chainId=='0x2d20'?'OMP-USDT':'USDT'}}</div>
          </div>
          <div class="b2-t2">{{$t('text.t133')}}：{{chainId=='0x2d20'?users.ompusdt:users.bscusdt}}</div>
        </div>
        <div class="box3 between-center b3-mb20">
          <input type="number" class="b3-input" v-model="usdt_num" />
          <!-- <div class="b3-t1">MAX</div> -->
        </div>
        <div class="box4 center-center">
          <img src="@/assets/img/img36.png" alt="" class="box4-i" />
        </div>
        <div class="box2 between-center">
          <div class="start-center">
            <img :src="chainId=='0x2d20'?$concatImg('img43'):$concatImg('img44')" alt="" class="b2-i1" />
            <div class="b2-t1">{{chainId=='0x2d20'?'USDT':'OMP-USDT'}}</div>
          </div>
          <div class="b2-t2">{{$t('text.t133')}}：{{chainId=='0x2d20'?users.bscusdt:users.ompusdt}}</div>
        </div>
        <div class="box3 between-center b3-mb15">
          <input type="number" disabled class="b3-input" v-model="omp_num" />
          <!-- <div class="b3-t1">MAX</div> -->
        </div>
        <div class="box2">
          <div class="b2-t1">{{$t('text.t231')}}</div>
        </div>
        <div class="addbox3 between-center b3-mb15">
          <input class="b3-input" v-model="account" />
        </div>
        <div class="box5">{{$t('text.t134')}}：1 USDT≈1 OMP-USDT</div>
        <div class="box6 center-center" @click="exchangeKL">{{$t('text.t135')}}</div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import BigNumber from "bignumber.js";
export default {
  components: {
  },
  data() {
    return {
      usdt_num: 0,
      isloading:false,
      users:{},
      account:''
    };
  },
  computed: {
    ...mapState(["chainId","language","config"]),
    omp_num(){
      return this.usdt_num
    }
  },
  created(){
    this.account = this.$local.get('shop:account')
    this.getuserMoney()
  },
  mounted() {},
  methods: {
    async exchangeKL(){
      try {
        this.isloading = true
        let arg = {
          destroy:this.omp_num,
          type:this.chainId == '0x2d20' ? 0 : 1,
          language:this.language
        }
        const res = await this.$http.post('/fixing/change',arg)
        const {pay,address,orderno} = res.data
        const result = await this.checkAllowance(pay)
        console.log(result);
        const hash = await this.shardsCollect(address,pay)
        this.sendHash(hash,orderno)
      } catch (error) {
        console.log(error);
        this.isloading = false
      }
    },
    async checkAllowance(pay){
      const user_address = this.$local.get('shop:account');
      console.log(user_address);
			const web3 = new this.Web3(ethereum);
			let f_max_num = new BigNumber(2).pow(256).minus(1);  //最大值
			const max_num = f_max_num.toString(16)
			console.log(max_num);
			const contract_from = this.chainId == '0x2d20' ? this.config.omp_usdt_contract : this.config.bsc_usdt_contract; // 被授权的合约地址
			const abi_from = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_usdt_abi) :  JSON.parse(this.config.bsc_usdt_abi);
			const contract_to = this.chainId == '0x2d20' ? this.config.omp_AnySwap : this.config.bsc_AnySwap; // 授权的合约地址
			
			const contract_proto = new web3.eth.Contract(abi_from, contract_from,{from:user_address});
			const isAllowance = await contract_proto.methods.allowance(user_address,contract_to).call();
			console.log(isAllowance);
			console.log(new BigNumber(isAllowance).gte(web3.utils.toWei(pay)));
			if(new BigNumber(isAllowance).gte(web3.utils.toWei(pay))){
				return true
			} else{
				const result1 = await contract_proto.methods.approve(contract_to,'0x'+max_num).send()
				console.log(result1);
        return true
			}
		},
    async shardsCollect(address,pay){
			const web3 = new this.Web3(ethereum);
      const contract_to = this.chainId == '0x2d20' ? this.config.omp_AnySwap : this.config.bsc_AnySwap;
      const abi_to = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_AnySwap_abi) : JSON.parse(this.config.bsc_AnySwap_abi);
			const nft_contract = new web3.eth.Contract(abi_to, contract_to,{from:address});
      const {transactionHash} = await nft_contract.methods.swapUsdt(this.account,web3.utils.toWei(pay)).send()
      return transactionHash
		},
    sendHash(hash,orderno){
			const arg = {
				hash,
				orderno,
				language:this.language
			}
			this.$http.post('/fixing/addHash',arg).then(rest=>{
				this.isloading = false
				this.$toast(rest.msg)
        this.getuserMoney()
			}).catch(()=>{
				this.isloading = false
			})
		},
    async getuserMoney(){
      await this.$http.post('/fixing/checkPrice')
      this.$http.get('/index/refresh').then(res=>{
        this.users = res.data
      })
    }
  },
};
</script>

<style lang="less">
.crosschain-style {
  .outbox {
    padding: 15px 0 30px;
    .box-card {
      width: 350px;
      border-radius: 20px;
      .van-divider {
        font-size: 9px;
        margin: 11px auto 26px;
      }
      .box1 {
        padding: 0 20px;
        .b1-t1 {
          width: 22px;
          height: auto;
        }
        .b1-t2 {
          font-size: 24px;
          font-weight: bold;
          color: #323232;
        }
      }
      .box2 {
        margin-bottom: 12px;
        .b2-i1 {
          width: 25px;
          height: auto;
          margin-right: 10px;
        }
        .b2-t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b2-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .box3 {
        height: 50px;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        padding: 0 20px;
        .b3-input {
          width: 80%;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #909090;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .addbox3 {
        height: 30px;
        background: #f4f4f4;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        padding: 0 20px;
        .b3-input {
          width: 100%;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #909090;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .b3-mb20 {
        margin-bottom: 40px;
      }
      .b3-mb15 {
        margin-bottom: 15px;
      }
      .box4 {
        margin-bottom: 31px;
        .box4-i {
          width: 52px;
          height: 52px;
        }
      }
      .box5 {
        margin-bottom: 77px;
        font-size: 12px;
        font-weight: 500;
        color: #323232;
      }
      .box6 {
        width: 200px;
        height: 45px;
        background: #2b2b2b;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        margin: 0 auto 15px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .box7 {
        width: 200px;
        height: 45px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        margin: 0 auto 30px;
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        border: 1px solid #323232;
        .b7-i1 {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>